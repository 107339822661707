document.addEventListener("turbolinks:load", function(){
    // JS for modal to override request status
    const buttonToOverrideStatus = document.getElementById("button-to-override-request-status");
    const overrideRequestStatusPopup = document.getElementById("override-request-status-popup");
    const overrideRequestStatusPopupCloseBtn = document.getElementById("override-request-status-popup-close-button");

    if  (buttonToOverrideStatus !== null){
        buttonToOverrideStatus.onclick = () => {
            overrideRequestStatusPopup.style.display = "flex";
        }
        overrideRequestStatusPopupCloseBtn.onclick = () => {
            overrideRequestStatusPopup.style.display = "none";
        }
    }


    // JS for modal of request menu
    const secCompanyNewRequestMenuBtn = document.getElementById("secretary-company-new-request-menu-button");
    const secCompanyNewRequestMenu = document.getElementById("secretary-company-new-request-menu");

    if(secCompanyNewRequestMenuBtn !== null){
        secCompanyNewRequestMenuBtn.onclick = () => {
            if (secCompanyNewRequestMenu.style.display == "block"){
                secCompanyNewRequestMenu.style.display = "none";
                secCompanyNewRequestMenuBtn.innerHTML = "Create New Request"
                secCompanyNewRequestMenuBtn.classList.add("tm-button-blue")
                secCompanyNewRequestMenuBtn.classList.remove("tm-button-red")
            } else {
                secCompanyNewRequestMenu.style.display = "block";
                secCompanyNewRequestMenuBtn.innerHTML = "Close Request Menu"
                secCompanyNewRequestMenuBtn.classList.remove("tm-button-blue")
                secCompanyNewRequestMenuBtn.classList.add("tm-button-red")
            }
        }
    }
})
