document.addEventListener("turbolinks:load", function(){

  // Handle Shareholder switch between corporate and individual
  const commonCompanyShareholderTypeInput = document.getElementById("common-company-shareholder-type-input");
  const commonCompanyShareholderNationalityField = document.getElementById("common-company-shareholder-nationality-field");
  const commonCompanyShareholderPassportField = document.getElementById("common-company-shareholder-passport-field");
  const commonCompanyShareholderPassportDateField = document.getElementById('common-company-shareholder-passport-date-field');
  const commonCompanyShareholderRaceField = document.getElementById("common-company-shareholder-race-field");
  const commonCompanyShareholderGenderField = document.getElementById("common-company-shareholder-gender-field");
  const commonCompanyShareholderCorporateOnlyFields = document.getElementById("common-company-shareholder-corporate-only-fields");
  const commonCompanyShareholderTitleField = document.getElementById("common-company-shareholder-title-field");
  const commonCompanyShareholderIcField= document.getElementById("common-company-shareholder-ic-field");
  const commonCompanyShareholderRegistrationField = document.getElementById('common-company-shareholder-registration-field');
  const commonCompanyShareholderBizOccupationField = document.getElementById("common-company-shareholder-biz-occupation-field");

  const commonCompanyShareholderBirthLabel = document.getElementById("common-company-shareholder-birth-label");
  const commonCompanyShareholderNameLabel = document.getElementById("common-company-shareholder-name-label");

  if (commonCompanyShareholderTypeInput !== null) {
    commonCompanyShareholderTypeInput.onchange = (e) => {
      e.stopPropagation();
      if (e.target.value.toLowerCase() == "corporate"){
        !commonCompanyShareholderNationalityField.classList.contains("hide") ? commonCompanyShareholderNationalityField.classList.add("hide") : '';
        !commonCompanyShareholderPassportField.classList.contains("hide") ? commonCompanyShareholderPassportField.classList.add("hide") : '';
        !commonCompanyShareholderPassportDateField.classList.contains("hide") ? commonCompanyShareholderPassportDateField.classList.add("hide") : '';
        !commonCompanyShareholderRaceField.classList.contains("hide") ?  commonCompanyShareholderRaceField.classList.add("hide") :  '';
        !commonCompanyShareholderGenderField.classList.contains("hide") ? commonCompanyShareholderGenderField.classList.add('hide') : '';
        !commonCompanyShareholderTitleField.classList.contains("hide") ? commonCompanyShareholderTitleField.classList.add("hide") : '';
        !commonCompanyShareholderIcField.classList.contains('hide') ? commonCompanyShareholderIcField.classList.add("hide") : '';
        !commonCompanyShareholderBizOccupationField.classList.contains('hide') ? commonCompanyShareholderBizOccupationField.classList.add("hide") : '';
        commonCompanyShareholderCorporateOnlyFields.classList.contains("hide") ? commonCompanyShareholderCorporateOnlyFields.classList.remove("hide") : '';
        commonCompanyShareholderRegistrationField.classList.contains("hide") ? commonCompanyShareholderRegistrationField.classList.remove('hide') : '';
        
        commonCompanyShareholderNameLabel.innerHTML = "Company Name";
        commonCompanyShareholderBirthLabel.innerHTML = "Date of Incorporation";
  
      } else if (e.target.value.toLowerCase() == "individual"){
        commonCompanyShareholderNationalityField.classList.contains("hide") ? commonCompanyShareholderNationalityField.classList.remove("hide") : '';
        commonCompanyShareholderPassportField.classList.contains("hide") ? commonCompanyShareholderPassportField.classList.remove("hide") : '';
        commonCompanyShareholderPassportDateField.classList.contains("hide") ? commonCompanyShareholderPassportDateField.classList.remove("hide") : '';
        commonCompanyShareholderRaceField.classList.contains("hide") ?  commonCompanyShareholderRaceField.classList.remove("hide") :  '';
        commonCompanyShareholderGenderField.classList.contains("hide") ? commonCompanyShareholderGenderField.classList.remove('hide') : '';
        commonCompanyShareholderTitleField.classList.contains("hide") ? commonCompanyShareholderTitleField.classList.remove("hide") : '';
        commonCompanyShareholderIcField.classList.contains("hide") ? commonCompanyShareholderIcField.classList.remove("hide") : '';
        commonCompanyShareholderBizOccupationField.classList.contains("hide") ? commonCompanyShareholderBizOccupationField.classList.remove("hide") : '';
        !commonCompanyShareholderCorporateOnlyFields.classList.contains("hide") ? commonCompanyShareholderCorporateOnlyFields.classList.add("hide") : '';
        !commonCompanyShareholderRegistrationField.classList.contains('hide') ? commonCompanyShareholderRegistrationField.classList.add('hide') : '';
        
        commonCompanyShareholderNameLabel.innerHTML = "Full name";
        commonCompanyShareholderBirthLabel.innerHTML = "Date of birth";
      }
    }
  }


})
  
