document.addEventListener("turbolinks:load", function(){
    const firstIncorporate = document.getElementById("first-visit-opt-incorporate");
    const firstTransfer = document.getElementById("first-visit-opt-transfer");
    const incorporteForm = document.getElementById("form-container-incorporate");
    const transferForm = document.getElementById("form-container-transfer");
    const companyIndexNavabarUserIcon = document.getElementById("user-navbar-user-icon");
    const companyIndexNavbarUserDropdown = document.getElementById("user-navbar-user-dropdown")
    const companyDashboard = document.getElementById("user-company-dashboard");
    const requestMenuIcon = document.getElementById("user-request-menu-icon");
    const requestMenuItems = document.getElementById("user-request-menu-items");
    const userDashboard = document.getElementById("user-dashboard");
    const searchCompanyNameInput = document.getElementById("search-company-name-input");
    const searchCompanyNameOutput = document.getElementById("search-company-name-output");
    
    // handle unfolding of new requests menu
    const userNewRequestItems = document.getElementById("user-new-request-items");
    const userNewRequestMenuTrigger = document.getElementById("user-new-request-menu-trigger");

    if (userNewRequestItems != null){
      userNewRequestMenuTrigger.onclick = () => {
        let height = userNewRequestItems.childElementCount * 6 + "rem";
        if (userNewRequestItems.clientHeight == "0"){
          userNewRequestItems.style.height = height;  
        } else if (userNewRequestItems.clientHeight > 0){
          userNewRequestItems.style.height = 0;
        }
      }
    }
     

    // Handle name searh form
    if (searchCompanyNameInput !== null){
      searchCompanyNameInput.onchange = () => {
        searchCompanyNameOutput.value = searchCompanyNameInput.value;
      }
    }


    $("[data-toggle='tooltip']").tooltip()
    
    // date picker initiation and customization
    $('[data-toggle="datepicker"]').attr('autocomplete','nope');
    $('[data-toggle="datepicker"]').datepicker({
      format: "yyyy-mm-dd"
    });
    
    $('.nesting-forms').on('cocoon:after-insert', function() {
       $('[data-toggle="datepicker"]').datepicker({
         format: "yyyy-mm-dd"
       });
    });

    const shareTypeInput = document.getElementById("allotment-share-type-input");
    const individualForm = document.getElementById("allotment-individual-form");
    const corporateForm = document.getElementById("allotment-corporate-form");

    if(individualForm !== null){  
        shareTypeInput.onchange = (e) => {
            e.stopPropagation();
            if(e.target.value.toLowerCase() === "individual"){
                if(!individualForm.classList.contains("hide")){
                    return
                } else {
                    individualForm.classList.remove("hide");
                    corporateForm.classList.add("hide");
                    for(let i = 0; i < individualForm.childElementCount; i++){
                      let elem = individualForm.children[i];
                      elem.children[1].disabled = false;
                    }
    
                    for(let i = 0; i < corporateForm.childElementCount; i++){
                      let elem = corporateForm.children[i];
                      elem.children[1].setAttribute("disabled", "true");
                    }
                }
            } else if(e.target.value.toLowerCase() === "corporate"){
                if(!corporateForm.classList.contains("hide")){
                    return 
                } else {
                    corporateForm.classList.remove("hide");
                    individualForm.classList.add("hide")
                    for(let i = 0; i < individualForm.childElementCount; i++){
                      let elem = individualForm.children[i];
                      elem.children[1].setAttribute("disabled", "true");
                    }
    
                    for(let i = 0; i < corporateForm.childElementCount; i++){
                      let elem = corporateForm.children[i];
                      elem.children[1].disabled = false;
                    }
                }
            }
        }
    }

    if(firstIncorporate !== null){
        const optSelected = (e) => {
            if(e.target.classList.contains('opt-incorporate')){
                let next = e.target.nextElementSibling
                next.classList.remove("bg-color-x-light-green");
                next.classList.add("bg-color-white");
                e.target.classList.remove("bg-color-white");
                e.target.classList.add("bg-color-x-light-green"); 
                
                incorporteForm.classList.remove("hide")
                if(!transferForm.classList.contains("hide")){
                  transferForm.classList.add("hide");
                }
              } else if (e.target.classList.contains('opt-transfer')){
                let previous = e.target.previousElementSibling
                previous.classList.remove("bg-color-x-light-green");
                previous.classList.add("bg-color-white");
                e.target.classList.remove("bg-color-white");
                e.target.classList.add("bg-color-x-light-green");

                transferForm.classList.remove("hide");
                if(!incorporteForm.classList.contains("hide")){
                  incorporteForm.classList.add("hide");
                }
              } else {
                current = e.target.parentNode
                if(current.classList.contains('opt-incorporate')){
                  let next = current.nextElementSibling
                  next.classList.remove("bg-color-x-light-green");
                  next.classList.add("bg-color-white");
                  current.classList.remove("bg-color-white");
                  current.classList.add("bg-color-x-light-green"); 
                  
                  incorporteForm.classList.remove("hide")
                  if(!transferForm.classList.contains("hide")){
                    transferForm.classList.add("hide");
                  }
                } else if (current.classList.contains('opt-transfer')){
                  let previous = current.previousElementSibling
                  previous.classList.remove("bg-color-x-light-green");
                  previous.classList.add("bg-color-white");
                  current.classList.remove("bg-color-white");
                  current.classList.add("bg-color-x-light-green");

                  transferForm.classList.remove("hide");
                  if(!incorporteForm.classList.contains("hide")){
                    incorporteForm.classList.add("hide");
                  }
                }
              }
        }

        firstIncorporate.onclick = optSelected;
        firstTransfer.onclick = optSelected;
    }

    if(companyIndexNavabarUserIcon != null) {
      companyIndexNavabarUserIcon.onclick = (e) => {
          e.stopPropagation();
          e.target.classList.add("color-trustmaven-green");
          companyIndexNavbarUserDropdown.classList.remove("hide");
      }

    }

    if(requestMenuIcon != null){

      requestMenuIcon.onclick = (e) => {
        e.stopPropagation();
        if(!e.target.classList.contains("color-trustmaven-green")){
          requestMenuItems.style.opacity = "1";
          e.target.classList.remove("color-orange-gold");
          e.target.classList.add("color-trustmaven-green");
        } else {
          requestMenuItems.style.opacity = "0";
          e.target.classList.remove("color-trustmaven-green");
          e.target.classList.add("color-orange-gold");
  
        }
      }
    }

    if(userDashboard != null && companyIndexNavbarUserDropdown != null){
      userDashboard.onclick = () => {
        if(!companyIndexNavbarUserDropdown.classList.contains("hide")){
          companyIndexNavabarUserIcon.classList.remove("color-trustmaven-green");
          companyIndexNavbarUserDropdown.classList.add("hide");
        }
      }
    }

    // if(companyDashboard != null) {
    //   companyDashboard.onclick = () => {
    //     if(!companyIndexNavbarUserDropdown.classList.contains("hide")){
    //       companyIndexNavabarUserIcon.classList.remove("color-trustmaven-green");
    //       companyIndexNavbarUserDropdown.classList.add("hide");
    //     }
  
    //     requestMenuItems.style.opacity = "0";
    //     requestMenuIcon.classList.remove("color-trustmaven-green");
    //     requestMenuIcon.classList.add("color-orange-gold");
    //   }
    // }
  
  // ensuring only one of service address checkbox is checked

  const serviceEqualPrimary = document.getElementById('service-equal-primary-checkbox');
  const serviceEqualEmail = document.getElementById('service-equal-email-checkbox');

  if(serviceEqualPrimary != null){
    serviceEqualPrimary.onchange = () => {
      if(serviceEqualPrimary.checked) {
        serviceEqualEmail.checked = false;
      }
    }
  }


  if(serviceEqualEmail != null){
    serviceEqualEmail.onchange = () => {
      if(serviceEqualEmail.checked) {
        serviceEqualPrimary.checked = false;
      }
    }
  }

})
    
  