import { SSL_OP_EPHEMERAL_RSA } from "constants";

document.addEventListener("turbolinks:load", function(){
    const adminNavIcon = document.getElementById('admin-nav-icon');
    const adminMiniLogo = document.getElementById('admin-nav-mini-logo');
    const adminDashboardLeft = document.getElementById('admin-dashboard-left');
    const adminDashboardRight = document.getElementById('admin-dashboard-right');
    const adminIconLogo = document.getElementById('admin-icon-logo');
    const companyNewBtn = document.getElementById('company-new-btn');
    const companyNewFormLinks = document.getElementById('company-new-form-links');
    const overworldBackBtn = document.getElementsByClassName("overworld-back-btn");
    
    
    function goBack(){
        window.history.back();
    }

    for(let i = 0; i < overworldBackBtn.length; i++){
        overworldBackBtn[i].onclick = goBack;
    }


    if (adminIconLogo != null){
        adminIconLogo.onclick = (e) => {
            if(adminDashboardLeft.offsetWidth > 100){
                adminDashboardLeft.style.width = adminDashboardLeft.offsetWidth - 195 + "px";
                adminDashboardRight.style.width = adminDashboardRight.offsetWidth + 195 + "px";
                setTimeout(() => {
                    if(adminMiniLogo.classList.contains('hide')){
                        adminMiniLogo.classList.remove('hide');
                        adminNavIcon.classList.add('hide');
                    }
                },500);
            } else if (adminDashboardLeft.offsetWidth < 100) {
                adminDashboardLeft.style.width = adminDashboardLeft.offsetWidth + 195 + "px";
                adminDashboardRight.style.width = adminDashboardRight.offsetWidth - 195 + "px";
                if(adminNavIcon.classList.contains('hide')){
                    adminNavIcon.classList.remove('hide');        
                    adminMiniLogo.classList.add('hide');
                }
            }
            // if(adminDashboardLeft.classList.contains('minimized')){
            //     adminDashboardLeft.classList.remove('minimized');
            //     adminDashboardLeft.classList.add("expanded");
            // } else if (adminDashboardLeft.classList.contains('expanded')){
            //     adminDashboardLeft.classList.remove("expanded");
            //     adminDashboardLeft.classList.add("minimized");
            // }
        }
    }

    if (companyNewBtn != null){
        companyNewBtn.onclick = () => {
            if(companyNewFormLinks.offsetWidth < 1){
                companyNewFormLinks.style.width = "956px";
                setTimeout(()=>{
                    for(let i = 0; i < companyNewFormLinks.childElementCount; i++){
                        companyNewFormLinks.children[i].style.opacity = "1";
                    }
                }, 500);
            } else if (companyNewFormLinks.offsetWidth > 1){
                for(let i = 0; i < companyNewFormLinks.childElementCount; i++){
                    companyNewFormLinks.children[i].style.opacity = "0";
                }
                companyNewFormLinks.style.width = "0";
            }

        }
    }
})